import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent, CardFooter, CardHeader } from "../ui/card";
import { Button } from "../ui/button";
import { CheckCircle2, Circle, Puzzle, CheckCheck, ArrowRight, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import SignUp from './SignUp';
import InstallExtension from './InstallExtension';
import { UseExtension, Website } from './UseExtension';
import Settings from './Settings';
import { Showcase, Viewer } from './Showcase';
import Completed from './Completed';

export default function Setup() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [finished, setFinished] = useState(false);
    const [demoStep, setDemoStep] = useState(0);
    const [blur, setBlur] = useState(true);

    useEffect(() => {
        if (searchParams.get('step') == null) {
            setSearchParams({ step: 'SignUp' });
        }
    }, [searchParams, setSearchParams]);

    const navigate = useNavigate();

    const stepFinished = () => {
        setFinished(true);
    };


    const steps = [
        {
            title: "Sign up",
            name: "SignUp",
            footer: "Sign up",
            description: "Let's make an account",
            icon: <Puzzle className="w-6 h-6" />,
            content: <SignUp stepFinished={stepFinished} />,
        },
        {
            title: "Choose Your Measurement System",
            name: "Settings",
            footer: "Settings",

            description: "Select the system you prefer to use in our service",
            icon: <CheckCheck className="w-6 h-6" />,
            content: <Settings stepFinished={stepFinished} />,
        },
        {
            title: "Install Chrome Extension",
            name: "InstallExtension",
            footer: "Install",

            description: "Add our extension to your browser",
            icon: <Puzzle className="w-6 h-6" />,
            content: <InstallExtension stepFinished={stepFinished} />,
        },
        {
            title: "Here is where the magic happens",
            name: "UseExtension",
            footer: "Magic",

            description: "Let's learn how to use the extension!",
            icon: <Puzzle className="w-6 h-6" />,
            content: <UseExtension stepFinished={stepFinished} setBlur={setBlur} />,
        },
        {
            title: "Let's now do the fun part",
            name: "Showcase",
            footer: "Fun",

            description: "You're all set to go!",
            icon: <CheckCheck className="w-6 h-6" />,
            content: <Showcase stepFinished={stepFinished} step={demoStep} />,
        },
        {
            title: "Congratulations!",
            name: "Completed",
            footer: "Completed",

            description: "You're all set to go!",
            icon: <CheckCheck className="w-6 h-6" />,
            content: <Completed stepFinished={stepFinished} />,
        },
    ];

    const currentIndex = steps.findIndex(step => step.name === searchParams.get('step'));
    const currentStepData = steps[currentIndex];

    if (currentStepData === undefined) {
        return null;
    }

    const handleNext = () => {
        if (currentIndex === steps.length - 1) {
            navigate("/pricing");
        } else {
            const nextStep = steps[currentIndex + 1];
            setSearchParams({ step: nextStep.name });
            setFinished(false);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            const prevStep = steps[currentIndex - 1];
            setSearchParams({ step: prevStep.name || 'Completed' });
        }
    }


    const cardHeader = (
        <CardHeader className="border-b p-2 pl-6">
            <motion.div
                className="flex items-center space-x-4"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                {currentStepData.icon}
                <div>
                    <h2 className="text-l font-semibold">{currentStepData.title}</h2>
                    <p className="text-gray-500">{currentStepData.description}</p>
                </div>
            </motion.div>
        </CardHeader>
    );


    const cardFooter = (
        <CardFooter className="flex flex-col space-y-4">
            <div className="flex justify-between w-full">
                {steps.slice(0, -1).map((step, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            transition={{ delay: index * 0.2, duration: 0.3 }}
                        >
                            {(index < currentIndex) || (index === currentIndex && finished) ? (
                                <CheckCircle2 className="w-8 h-8 text-primary" />
                            ) : index === currentIndex ? (
                                <Circle className="w-8 h-8 text-primary" />
                            ) : (
                                <Circle className="w-8 h-8 text-gray-300" />
                            )}
                        </motion.div>
                        <motion.div
                            className="text-xs mt-1"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: index * 0.2 + 0.1, duration: 0.3 }}
                        >
                            {step.footer}
                        </motion.div>
                    </div>
                ))}
            </div>
            <motion.div
                className="flex justify-between items-center w-full pt-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 0.3 }}
            >
                <Button
                    variant="outline"
                    onClick={handlePrevious}
                    disabled={currentIndex === 0}
                >
                    <ArrowLeft className="w-4 h-4 mr-2" /> Back
                </Button>

                <Button onClick={handleNext} disabled={!finished}>
                    {searchParams.get('step') === "Completed" ? 'Finish' : 'Next'}{' '}
                    <ArrowRight className="w-4 h-4 ml-2" />
                </Button>
            </motion.div>
        </CardFooter>
    );


    return (
        <div className={`flex max-h-screen  min-h-screen bg-gray-100 ${searchParams.get('step') === "Showcase" || searchParams.get('step') === "UseExtension" ? "flex-row-reverse" : "items-center justify-center "}`}>
            <Card className={`${searchParams.get('step') === "Showcase" || searchParams.get('step') === "UseExtension" ? "w-full max-w-sm" : "w-full max-w-2xl"} bg-white text-black shadow-lg overflow-hidden`}>
                {cardHeader}
                <CardContent className="pb-0 pt-6">
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={searchParams.get('step')}
                            initial={{ opacity: 0, x: 10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            {currentStepData.content}
                        </motion.div>
                    </AnimatePresence>
                </CardContent>
                {cardFooter}
            </Card>

            {searchParams.get('step') === "Showcase" && (
                <div className="flex-1">
                    <Viewer setStep={setDemoStep} step={demoStep} />
                </div>
            )}

            {searchParams.get('step') === "UseExtension" && (
                <div className="flex-1 min-h-screen overflow-auto " style={blur ? { filter: 'blur(10px)' } : {}} >
                    <Website />
                </div>


            )}
        </div>
    );
}
