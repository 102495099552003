import { useState, useRef, useCallback, useEffect } from 'react'
import { Button } from "./ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog"
import { Checkbox } from "./ui/checkbox"

import { Label } from "./ui/label"
import { Textarea } from "./ui/textarea"
import { Canvas } from '@react-three/fiber'
import { Bounds, useBounds, OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { Model } from './Models'
import { CheckCircle2, AlertCircle } from 'lucide-react';

const CameraControls = () => {
    const bounds = useBounds()
    useEffect(() => {
        bounds.refresh().clip().fit()
    }, [bounds])
    return <OrbitControls makeDefault autoRotate
        autoRotateSpeed={-5} />
}


export default function RatingModal({ item, onSubmit }) {
    const [showFeedbackForm, setShowFeedbackForm] = useState(false)
    const [selectedReasons, setSelectedReasons] = useState([])
    const [otherReason, setOtherReason] = useState('')
    const otherReasonRef = useRef(null);


    const handleReasonToggle = useCallback((reason) => {
        setSelectedReasons(prev =>
            prev.includes(reason)
                ? prev.filter(r => r !== reason)
                : [...prev, reason]
        )
    }, [])

    const handleSubmit = () => {
        const reasons = selectedReasons.includes('other')
            ? [...selectedReasons.filter(r => r !== 'other'), otherReason]
            : selectedReasons

        console.log("Submitting feedback:", reasons)
        onSubmit({ id: item.model_id, approved: false, feedbackList: reasons })
    }

    useEffect(() => {
        if (selectedReasons.includes('other') && otherReasonRef.current) {
            otherReasonRef.current.focus();
        }
    }, [selectedReasons]);

    return (
        <Dialog open>
            <DialogContent className="sm:max-w-[450px]">
                <DialogHeader>
                    <DialogTitle>Evaluate the 3D Model</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="h-[250px] w-full rounded-lg shadow-lg overflow-hidden">
                        <Canvas>
                            <PerspectiveCamera
                                makeDefault
                                position={[Math.sqrt(2), 1, Math.sqrt(2)]} // Position the camera at a 45-degree angle
                                rotation={[-Math.PI / 6, Math.PI / 4, 0]}  // Rotate the camera to look at the object
                            />
                            <Bounds fit clip observe margin={1.2}>
                                <CameraControls />
                                <ambientLight intensity={3} />
                                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                                <pointLight position={[-10, -10, -10]} />
                                <Model
                                    key={item.name}
                                    url={item.data_url}
                                    name={item.name}
                                    {...item.transform}
                                />
                            </Bounds>
                        </Canvas>
                    </div>

                    {!showFeedbackForm && (
                        <div className="space-y-4">
                            <p className="text-sm text-gray-600 font-bold">How do the orientation, resemblance, and dimensions look overall?</p>
                            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0 sm:space-x-8">
                                <div className="text-sm text-gray-600 bg-gray-100 p-4 rounded-lg shadow-lg">
                                    <p className="font-bold mb-2">Dimensions:</p>
                                    <ul className="list-disc list-inside">
                                        {Object.entries(item.dimension).map(([key, value]) => (
                                            key !== 'unit' && (
                                                <li key={key}>
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}: {parseFloat(value.toFixed(2)).toString() + item.dimension.unit}
                                                </li>
                                            )
                                        ))}
                                    </ul>
                                </div>
                                <div className="flex space-x-4">
                                    <Button onClick={() => onSubmit({ id: item.model_id, approved: true, feedbackList: [] })} variant="green" className="w-18">
                                        <CheckCircle2 className="mr-2 h-4 w-4" />
                                        Good
                                    </Button>
                                    <Button onClick={() => setShowFeedbackForm(true)} variant="destructive" className="w-18">
                                        <AlertCircle className="mr-2 h-4 w-4" />
                                        Bad
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showFeedbackForm && (
                        <div className="space-y-4">
                            <p className="text-sm font-medium">Select all that apply:</p>
                            <div className="space-y-2">
                                {['dimension', 'model', 'orientation', 'other'].map((reason) => (
                                    <div key={reason} className="flex items-center space-x-2">
                                        <Checkbox
                                            id={reason}
                                            checked={selectedReasons.includes(reason)}
                                            onCheckedChange={() => handleReasonToggle(reason)}
                                        />
                                        <Label htmlFor={reason}>
                                            {reason === 'model' && 'Model does not match the original image'}
                                            {reason === 'dimension' && 'Incorrect dimensions'}
                                            {reason === 'orientation' && 'Incorrect orientation'}
                                            {reason === 'other' && 'Other'}
                                        </Label>
                                    </div>
                                ))}
                            </div>

                            {selectedReasons.includes('other') && (
                                <Textarea
                                    ref={otherReasonRef}
                                    placeholder="Please explain the issue..."
                                    value={otherReason}
                                    onChange={(e) => setOtherReason(e.target.value)}
                                    className="mt-2"
                                />
                            )}
                        </div>
                    )}
                </div>

                <DialogFooter>
                    {showFeedbackForm && selectedReasons.length > 0 && (
                        <Button
                            onClick={handleSubmit}
                            className="w-full"
                            disabled={selectedReasons.includes('other') && otherReason.trim() === ''}
                        >
                            Submit Feedback
                        </Button>
                    )}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};