import { useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Label } from "./ui/label"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogClose,
} from "./ui/dialog"
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"

import { Model, Room } from './Models'
import { getLatestItems, supabase, convertToMeters } from '../lib/misc'

const DimensionsForm = ({ room, setRoom, handleSubmit, handleReturn, handleDelete, measurementSystem }) => {
    const [changed, setChanged] = useState(false)

    const handleChange = (event) => {
        const { name, value } = event.target
        const normalizedValue = name !== 'name' ? value.replace(',', '.') : value

        if (name !== 'name' && isNaN(parseFloat(normalizedValue))) return;

        if (name === 'name') {
            setRoom((prevRoom) => ({
                ...prevRoom,
                [name]: value,
            }))
        } else {
            setRoom((prevRoom) => ({
                ...prevRoom,
                layout: {
                    ...prevRoom.layout,
                    [name]: parseFloat(normalizedValue),
                },
            }))
        }
        setChanged(true)
    }

    const unitLabel = measurementSystem === 'imperial' ? 'ft' : 'm'

    return (
        <Card className="w-full max-w-md p-2 md:p-4 ">
            <CardHeader>
                <CardTitle>Set Up Your Room</CardTitle>
            </CardHeader>
            <CardContent>
                <form onSubmit={handleSubmit} className="space-y-2">
                    <div className="space-y-1">
                        <Label htmlFor="name">Room Name</Label>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            value={room.name}
                            onChange={handleChange}
                            required
                            placeholder="e.g., Living Room"
                        />
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="width">Width ({unitLabel})</Label>
                        <Input
                            type="number"
                            id="width"
                            name="width"
                            defaultValue={room.layout.width}
                            onChange={handleChange}
                            required
                            step="0.1"
                            min="0.1"
                        />
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="length">Length ({unitLabel})</Label>
                        <Input
                            type="number"
                            id="length"
                            name="length"
                            defaultValue={room.layout.length}
                            onChange={handleChange}
                            required
                            step="0.1"
                            min="0.1"
                        />
                    </div>
                    <div className="space-y-1">
                        <Label htmlFor="height">Height ({unitLabel})</Label>
                        <Input
                            type="number"
                            id="height"
                            name="height"
                            defaultValue={room.layout.height}
                            onChange={handleChange}
                            required
                            step="0.1"
                            min="0.1"
                        />
                    </div>
                    {room.id && (
                        <div className="flex space-x-2 mt-2">
                            <Button type="submit" disabled={!changed} className="flex-1">
                                Update Room
                            </Button>
                            <Dialog>
                                <DialogTrigger asChild>
                                    <Button variant="destructive" className="flex-1">Delete Room</Button>
                                </DialogTrigger>
                                <DialogContent>
                                    <DialogHeader>
                                        <DialogTitle>Are you sure you want to delete this room?</DialogTitle>
                                        <DialogDescription>
                                            This action cannot be undone.
                                        </DialogDescription>
                                    </DialogHeader>
                                    <div className="flex justify-end space-x-2">
                                        <Button variant="outline" onClick={handleDelete}>Yes, Delete</Button>
                                        <DialogClose asChild>
                                            <Button>Cancel</Button>
                                        </DialogClose>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                    )}
                    {!room.id && (
                        <Button type="submit" className="w-full mt-2">Create Room</Button>
                    )}
                    <Button type="button" onClick={handleReturn} variant="secondary" className="w-full mt-2">
                        Return
                    </Button>
                </form>
            </CardContent>
        </Card>
    )
}

const RoomPreview = ({ layout, items, assets }) => {
    const [convertedLayout, setConvertedLayout] = useState(layout);

    useEffect(() => {
        setConvertedLayout(convertToMeters(layout));
    }, [layout]);

    return (
        <Canvas camera={{ position: [8, 8, 8], fov: 50 }}>
            <Room {...convertedLayout} />
            <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} />
            <gridHelper args={[10, 10]} />
            {items.map((item) => {
                const data_url = assets[item.model_id].data_url
                return (
                    <Model
                        key={item.model_id}
                        url={data_url}
                        name={item.name}
                        {...item.transform}
                    />
                )
            })}
            <pointLight position={[0, 3, 0]} intensity={10} />
            <hemisphereLight
                color="#ffffff"
                groundColor="#b9b9b9"
                position={[-7, 25, 13]}
                intensity={0.85}
            />
        </Canvas>
    )
}

export default function RoomLayoutCreator({ assets, setRoom, setRooms, room, setMode }) {
    const measurementSystem = localStorage.getItem('measurementSystem') || 'metric';

    const defaultRoom = {
        id: null,
        name: '',
        layout: {
            unit: measurementSystem === 'imperial' ? 'ft' : 'm',
            width: measurementSystem === 'imperial' ? 16 : 5,
            length: measurementSystem === 'imperial' ? 16 : 5,
            height: measurementSystem === 'imperial' ? 9 : 3,
        },
        inventory: { updated: 0, items: [] }
    }

    // Initialize localRoom without any conversion
    const [localRoom, setLocalRoom] = useState(() => {
        if (room) {
            return { ...room }
        } else {
            return defaultRoom
        }
    })

    const handleSubmit = async (event) => {
        event.preventDefault()

        // No need to convert dimensions, just save them as is

        if (!room) {
            try {
                const { data, error } = await supabase
                    .rpc('create_room', {
                        name: localRoom.name,
                        layout: localRoom.layout,
                        slot: 0
                    })

                if (error) throw error

                const newRoom = { ...localRoom, id: data[0].room_id }
                setRooms((prevRooms) => [...prevRooms, newRoom])
                setRoom(newRoom)
            } catch (error) {
                console.error('Error creating room:', error)
            }
        } else if (room && JSON.stringify(localRoom) !== JSON.stringify(room)) {
            try {
                const { error } = await supabase
                    .rpc('update_room', {
                        _id: localRoom.id,
                        _name: localRoom.name,
                        _layout: localRoom.layout
                    })

                if (error) throw error

                setRooms((prevRooms) => {
                    const updatedRooms = [...prevRooms]
                    const existingRoomIndex = updatedRooms.findIndex((r) => r.id === room.id)
                    if (existingRoomIndex !== -1) {
                        updatedRooms[existingRoomIndex] = { ...room, ...localRoom }
                    }
                    return updatedRooms
                })

                setRoom(localRoom)
            } catch (error) {
                console.error('Error updating room:', error)
            }
        }

        setMode('edit-room')
    }

    const handleDelete = async () => {
        if (!room || !room.id) {
            console.error('No room to delete')
            return
        }

        try {
            const { error } = await supabase
                .from('rooms')
                .delete()
                .eq('id', room.id)

            if (error) throw error

            localStorage.removeItem(`items:${room.id}`)

            setRoom(null)
            setRooms((prevRooms) => prevRooms.filter((r) => r.id !== room.id))
            setMode('select-room')
        } catch (error) {
            console.error('Error deleting room:', error)
        }
    }

    return (
        <div className="flex flex-col-reverse md:flex-row h-screen select-none">
            <div className="w-full md:w-1/3 p-2 md:p-4  flex justify-center items-center">
                <DimensionsForm
                    room={localRoom}
                    setRoom={setLocalRoom}
                    handleSubmit={handleSubmit}
                    handleReturn={() => setMode(room ? 'edit-room' : 'select-room')}
                    handleDelete={handleDelete}
                    measurementSystem={measurementSystem}
                />
            </div>
            <div className="w-full md:w-2/4 h-full">
                <RoomPreview
                    layout={localRoom.layout}
                    assets={assets}
                    items={getLatestItems(localRoom)}
                />
            </div>
        </div>
    )
}
