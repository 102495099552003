/*global chrome*/
import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import Check from './Check';

function InstallExtension({ stepFinished }) {
    const [installed, setInstalled] = useState(false);

    useEffect(() => {
        const checkExtensionInstalled = () => {
            let port;
            if (chrome.runtime !== undefined) {
                port = chrome.runtime.connect(process.env.REACT_APP_CHROME_EXTENSION_ID);
            }

            if (port) {
                setInstalled(true);
                port.disconnect();
                return true; // Indicate that the extension is installed
            }
            return false; // Indicate that the extension is not installed
        };

        // Run the check once before starting the interval
        if (!checkExtensionInstalled()) {
            const interval = setInterval(() => {
                if (checkExtensionInstalled()) {
                    clearInterval(interval); // Stop the interval when installed
                }
            }, 500);

            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (installed) {
            stepFinished();
        }
    }, [installed]);


    if (installed) {
        return <Check title={"Extension Detected!"} description={"Nice! Your extension is ready to roll. Let’s get to the next step"} />
    }

    return (
        <div className="space-y-4">

            <video className="w-full h-auto" muted autoplay loop>
                <source src="path/to/your/video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <p>Install our Chrome extension to get started:</p>
            <div className="flex items-center justify-center space-x-4">

                {!installed && (
                    <Button
                        onClick={() => {
                            window.location.href = 'https://chrome.google.com/webstore/detail/chkaneemggbglcpaihfkcclnlolbcnih';
                        }}
                    >
                        Go to Chrome Web Store
                    </Button>
                )}
                <div className="flex items-center space-x-2">
                    <div
                        className={`w-3 h-3 rounded-full ${installed ? 'bg-green-500' : 'bg-gray-300'
                            }`}
                    ></div>
                    <span>
                        {installed ? 'Installation detected' : 'Waiting for installation'}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default InstallExtension;
