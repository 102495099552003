import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function InsufficientCredits() {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to /editor after 3 seconds
        const timer = setTimeout(() => {
            navigate('/editor');
        }, 3000);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="text-3xl font-bold text-red-600 mb-4">Insufficient Credits</h1>
            <p className="text-lg text-gray-700">You will be redirected to the editor shortly...</p>
        </div>
    );
}

export default InsufficientCredits;