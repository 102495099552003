import React from 'react';
import { useState } from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
    const [lastUpdated] = useState('August 28, 2024');
    const [contactEmail] = useState('sobadesignapp@gmail.com');

    return (
        <div className="privacy-policy">
            <h1>Privacy Policy for Soba Chrome Extension</h1>
            <p className="last-updated">Last Updated: {lastUpdated}</p>

            <section>
                <h2>1. Introduction</h2>
                <p>
                    This Privacy Policy explains how the Soba Chrome Extension ("we", "our", or "the Extension")
                    collects, uses, and shares user data in connection with the Soba web application, a home
                    decoration platform. We are committed to protecting your privacy and handling your data
                    in a transparent and secure manner.
                </p>
            </section>

            <section>
                <h2>2. Data Collection and Use</h2>
                <p>The Extension collects and processes the following types of data:</p>
                <ul>
                    <li>Images: The Extension captures and uploads images from web pages you visit, as initiated by you, to enhance your experience with the Soba web application.</li>
                    <li>Browsing Data: The Extension collects the URL of the web page from which an image is captured.</li>
                    <li>Text Input: Any text you enter when capturing an image is collected and associated with that image.</li>
                </ul>
            </section>

            <section>
                <h2>3. Purpose of Data Collection</h2>
                <p>We collect and process this data to:</p>
                <ul>
                    <li>Create accurate 3D models of items, scaled to their real-world size, using the images and text you provide</li>
                    <li>Save the link to the original website where each item was found, enabling you to easily locate and potentially purchase items later</li>
                    <li>Enhance your experience with the Soba web application by populating your virtual room with these 3D models</li>
                </ul>
            </section>

            <section>
                <h2>4. Data Sharing</h2>
                <p>Your data is shared with:</p>
                <ul>
                    <li>Soba's backend servers: To process uploaded images and associate them with your account</li>
                </ul>
                <p>We do not sell your personal data to any third parties.</p>
            </section>

            <section>
                <h2>5. Data Storage and Security</h2>
                <p>
                    All data is transmitted securely using HTTPS. Your authentication token is stored locally
                    in your browser using Chrome's secure storage API. We implement appropriate technical and
                    organizational measures to protect your data against unauthorized or unlawful processing
                    and against accidental loss, destruction, or damage.
                </p>
            </section>

            <section>
                <h2>6. User Rights</h2>
                <p>You have the right to:</p>
                <ul>
                    <li>Access the personal data we hold about you</li>
                    <li>Request correction or deletion of your personal data</li>
                    <li>Object to or restrict the processing of your personal data</li>
                    <li>Request a copy of your data in a portable format</li>
                </ul>
                <p>To exercise these rights, please contact us at {contactEmail}.</p>
            </section>

            <section>
                <h2>7. Changes to This Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. We will notify you of any changes
                    by posting the new Privacy Policy on this page and updating the "Last Updated" date.
                </p>
            </section>

            <section>
                <h2>8. Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at {contactEmail}.
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;