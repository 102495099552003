import { Auth as SupabaseAuth } from '@supabase/auth-ui-react'
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useNavigate } from 'react-router-dom'
import { supabase } from '../lib/misc'

const Auth = ({ view }) => {
    const navigate = useNavigate()

    supabase.auth.onAuthStateChange(async (event) => {
        if (event === "SIGNED_IN") {
            navigate("/editor");
        }
    });

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column" }}>
            <h1>Soba.ai</h1>
            <div style={{ width: "80%", maxWidth: "400px", margin: "2rem" }}>
                <SupabaseAuth
                    supabaseClient={supabase}
                    providers={[]}
                    appearance={{
                        theme: ThemeSupa,
                        variables: {
                            default: {
                            },
                        },
                    }}
                    view={view}
                />
            </div>
        </div>
    )
}

export default Auth;