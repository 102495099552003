import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from "../ui/button"
import { RulerIcon } from 'lucide-react'

export default function Settings({ stepFinished }) {
    const [measurementSystem, setMeasurementSystem] = useState(null)

    useEffect(() => {
        if (measurementSystem) {
            localStorage.setItem('measurementSystem', measurementSystem);
            stepFinished();
        }
    }, [measurementSystem])



    const systems = [
        {
            name: 'Metric',
            examples: 'meter, decimeter, centimeter',
        },
        {
            name: 'Imperial',
            examples: 'yard, foot, inches',
        },
    ]

    return (


        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pb-4">
            {systems.map((system, index) => (
                <motion.div
                    key={system.name}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.2 }}
                >
                    <Button
                        variant={measurementSystem === system.name.toLowerCase() ? "default" : "outline"}
                        className="w-full h-40 flex flex-col items-center justify-center text-center p-4 space-y-2 rounded-lg"
                        onClick={() => { setMeasurementSystem(system.name.toLowerCase()); }}
                    >
                        <RulerIcon className="w-8 h-8 mb-2" />
                        <span className="text-lg font-semibold">{system.name} System</span>
                        <span className="text-sm text-gray-500">e.g., {system.examples}</span>
                    </Button>
                </motion.div>
            ))}
        </div>)

}