/*global chrome*/
import React, { useEffect, useState, useRef } from 'react';

import RoomEditor from '../components/RoomEditor';
import RoomSelection from '../components/RoomSelection';
import RoomLayoutCreator from '../components/RoomLayoutCreator';
import { supabase, sendJWT } from '../lib/misc';
import FailedModal from '../components/FailedModal';


const Editor = () => {
    const tabId = crypto.randomUUID();
    const [assets, setAssets] = useState({});
    const [room, setRoom] = useState(null);
    const [rooms, setRooms] = useState(null);
    const [mode, setMode] = useState('select-room');
    const [queuedModels, setQueuedModels] = useState([]);
    const [failedModels, setFailedModels] = useState([]);
    const [user, setUser] = useState(null);

    const port = useRef(null);

    const submitFeedback = async ({ id, approved, feedbackList }) => {
        supabase.rpc('rate_model', {
            p_model_id: id,
            p_approved: approved,
            p_feedback_list: feedbackList,
        })
            .then(({ data: modelId, error }) => {

                console.log("Model rated:", modelId);
                console.log("error:", error);
                if (error) {
                    console.error("Error rating model:", error);
                    return;
                }

                console.log("Model rated successfully:", modelId);

                setAssets(prevAssets => ({
                    ...prevAssets,
                    [id]: {
                        ...prevAssets[id],
                        approved: approved
                    }
                }));
            })
            .catch(err => {
                console.error("Unexpected error:", err);
            });

    }





    const fetchQueuedModels = async () => {

        const pollInterval = 30000; // Poll every 10 seconds

        const currentQueuedModels = await new Promise(resolve => setQueuedModels(models => {
            resolve(models);
            return models;
        }));

        try {
            const modelIds = currentQueuedModels.map(model => model.id);

            if (modelIds.length === 0) {
                setTimeout(fetchQueuedModels, pollInterval);
                return;
            }

            const { data, error } = await supabase.rpc('get_models_by_ids', { model_ids: modelIds });

            if (error) {
                throw error;
            }

            const processedIds = data.map(item => item.id);
            if (processedIds.length > 0 && port.current) {
                port.current.postMessage({
                    action: "removeFromQueue",
                    modelIds: processedIds
                });
            }

            setQueuedModels(prevModels => prevModels.filter(model => !processedIds.includes(model.id)));


            const succededModels = data.filter(model => model.status === "success");
            const failedModels = data.filter(model => model.status === "queued_failed");

            failedModels.forEach(failedModel => {
                const correspondingModel = currentQueuedModels.find(queuedModel => queuedModel.id === failedModel.id);
                if (correspondingModel) {
                    failedModel.img_url = correspondingModel.img_url;
                }
            });

            setFailedModels(prevFailedModels => [...prevFailedModels, ...failedModels]);

            const dataObjects = succededModels.reduce((acc, asset) => {
                const { id, ...rest } = asset;
                acc[id] = { ...rest, new: true };

                return acc;
            }, {});

            setAssets(prevAssets => ({
                ...dataObjects,
                ...prevAssets
            }));

            setTimeout(fetchQueuedModels, pollInterval);

        } catch (error) {
            console.error('Error:', error.message);

            setTimeout(fetchQueuedModels, pollInterval);
        }
    };

    function setupPort() {
        if (chrome.runtime !== undefined) {
            port.current = chrome.runtime.connect(process.env.REACT_APP_CHROME_EXTENSION_ID, { name: tabId });
        }

        if (port.current) {
            sendJWT(port.current);

            port.current.onMessage.addListener((request) => {
                if (request.action === "queuedModels") {
                    setQueuedModels([...request.queuedModels]);
                }

                if (request.action === "addModelToQueue") {
                    setQueuedModels(prevModels => [...prevModels, request.model]);
                }

            });

            port.current.onDisconnect.addListener(() => {
                console.log('Port disconnected. Attempting to reconnect...');
                setupPort();
            });
        }
    }

    useEffect(() => {

        setupPort()

        const fetchRooms = async () => {
            try {
                const { data, error } = await supabase.rpc('get_rooms');

                if (error) {
                    throw error;
                }

                setRooms(data);
            }
            catch (error) {
                console.error('There was a problem with your fetch operation:', error);
            }
        };

        const fetchAssets = async () => {
            try {
                const { data, error } = await supabase.rpc('get_models');

                if (error) {
                    throw error;
                }

                const dataObject = data.reduce((acc, asset) => {
                    const { id, ...rest } = asset;
                    acc[id] = rest;
                    return acc;
                }, {});
                setAssets(dataObject);

                const dataObjectIds = new Set(Object.keys(dataObject));

                const removedModelIds = [];
                setQueuedModels(prevModels => prevModels.filter(model => {
                    if (dataObjectIds.has(model.id)) {
                        removedModelIds.push(model.id);
                        return false;
                    }
                    return true;
                }));

                if (port.current && removedModelIds.length > 0) {
                    port.current.postMessage({
                        action: "removeFromQueue",
                        modelIds: removedModelIds,
                    });
                }
                fetchQueuedModels();

            } catch (error) {
                console.error('There was a problem with your fetch operation:', error);
                return [];
            }
        };

        async function fetchUser() {
            const { data, error: userError } = await supabase.auth.getUser();
            if (userError) {
                console.error('Error fetching user:', userError);
                return;
            }
            console.log('User:', data, data.user.id);
            const { data: user, error } = await supabase
                .from('users')
                .select('*')
                .eq('id', data.user.id);

            setUser(user[0]);
            console.log(user, error);
        }

        fetchUser();
        fetchRooms();
        fetchAssets();

    }, []);

    if (!rooms || !user || !assets) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                </div>
            </div>
        );
    }

    if (mode === 'select-room') {
        return (

            <RoomSelection
                rooms={rooms}
                user={user}
                onRoomSelect={(id) => { setMode('edit-room'); setRoom(id); }}
                onCreateRoom={() => { setMode('create-room'); setRoom(null); }}
            />

        );
    }

    if (mode === 'create-room') {
        return (
            <RoomLayoutCreator
                assets={assets}
                setRoom={setRoom}
                setRooms={setRooms}
                room={room}
                setMode={setMode}
            />
        );
    }

    if (mode === 'edit-room') {
        return (
            <>
                {failedModels.length > 0 && <FailedModal failedModels={failedModels} setFailedModels={setFailedModels} />}
                <RoomEditor
                    room={room}
                    setMode={setMode}
                    assets={assets}
                    queued={queuedModels}
                    submitFeedback={submitFeedback}
                />
            </>
        );
    }
};

export default Editor;