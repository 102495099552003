import React, { useEffect, useState } from 'react'
import { X, ArchiveRestore, RotateCw, Move3d } from 'lucide-react'
import { Button } from "./ui/button"
import { useSnapshot } from 'valtio';
import styles from '../styles/SelectedModel.module.css';

export const SelectedModel = ({ items, assets, remove, state }) => {
    const snap = useSnapshot(state);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const selectedItem = items.find(item => item.name === snap.current);
        if (selectedItem) {
            const { name, img_url, dimension, src_url, price, currency } = assets[selectedItem.model_id];
            const item_id = selectedItem.name;
            setSelectedItem({ name, img_url, dimension, item_id, src_url, price, currency });
        }
    }, [snap.current]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                state.current = null;
            }
        };

        document.addEventListener('keydown', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [state]);

    if (!selectedItem) {
        return null;
    }

    return (
        <div className={`${styles.container} bg-gray-200 rounded-t-lg p-1 select-none`} style={{ display: snap.current != null ? 'flex' : 'none' }}>
            <div className="flex items-center space-x-2">
                <img src={selectedItem.img_url} alt={selectedItem.name} width={"100px"} className="rounded" draggable="false" />
                <div>
                    <h3 className="font-bold text-sm">{selectedItem.name}</h3>
                    {selectedItem.price && (<p className="text-xs mb-1">
                        {selectedItem.price} {selectedItem.currency}
                    </p>)}
                    <p className="text-xs">
                        {selectedItem.dimension.width.toFixed(2)} x {selectedItem.dimension.length.toFixed(2)} x {selectedItem.dimension.height.toFixed(2)} {selectedItem.dimension.unit}
                    </p>
                    {selectedItem.src_url && (
                        <a href={selectedItem.src_url} target="_blank" rel="noreferrer" className="text-xs text-blue-500">
                            {selectedItem.src_url.length > 25 ? `${selectedItem.src_url.substring(0, 25)}...` : selectedItem.src_url}
                        </a>
                    )}
                </div>
            </div>
            <div className="mode-buttons flex flex-row md:flex-col">
                <Button
                    variant="ghost"
                    className={`flex items-center justify-center flex-1 rounded-none border-r border-gray-300 h-full  ${snap.mode === 0 ? "bg-white" : ""}`}
                    onClick={() => state.mode = 0}
                >
                    <Move3d className="mr-2 h-4 w-4" />
                    Move
                </Button>
                <Button
                    variant="ghost"
                    className={`flex items-center justify-center flex-1 border-r-0 rounded-none  border-gray-300 h-full md:border-r ${snap.mode === 1 ? "bg-white" : ""}`}
                    onClick={() => state.mode = 1}
                >
                    <RotateCw className="mr-2 h-4 w-4" />
                    Rotate
                </Button>
            </div>
            <div className="buttons flex">
                <Button
                    variant="ghost"
                    className="flex items-center justify-center flex-1 rounded-none border-r border-t border-gray-300 h-full md:border-t-0"
                    onClick={(e) => { e.stopPropagation(); remove(selectedItem.item_id); state.current = null; }}
                >
                    <ArchiveRestore className="mr-2 h-4 w-4" />
                    Store Away
                </Button>
                <Button
                    variant="ghost"
                    className="flex items-center justify-center flex-1 rounded-none border-r-0 border-t border-gray-300 h-full md:border-t-0 md:border-r"
                    onClick={() => state.current = null}
                >
                    <X className="mr-2 h-4 w-4" />
                    Cancel (Esc)
                </Button>
            </div>

        </div >
    )
}