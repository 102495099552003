import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { ContactShadows } from '@react-three/drei';
import { Model, Room, Controls, state } from './Models';
import SquareGrid from './SquareGrid';
import '../styles/RoomView.css';
import { getTransform, saveInventoryLocal, saveInventoryCloud, getLatestItems, convertToMeters } from '../lib/misc';
import { SelectedModel } from '../components/SelectedModel';
import RatingModal from './RatingModal';

import { Card } from './ui/card'
import RoomSummary from './RoomSummary';
import RoomControls from './RoomControls';

const AccessScene = ({ sceneRef }) => {
    const { scene } = useThree();
    useEffect(() => {
        sceneRef.current = scene;
    }, [scene, sceneRef]);
    return null;
};

const RoomEditor = ({ room, setMode, assets, queued, submitFeedback }) => {
    const [items, setItems] = useState(getLatestItems(room));
    const sceneRef = useRef();
    const itemsRef = useRef(items);

    const [ratingModel, setRatingModel] = useState(false);
    const [convertedLayout, setConvertedLayout] = useState(room.layout);

    useEffect(() => {
        setConvertedLayout(convertToMeters(room.layout));
    }, [room.layout]);

    useEffect(() => {
        itemsRef.current = items;
    }, [items]);

    const add = async (id, state) => {
        const tmp = {
            transform: await getTransform(assets[id]),
            name: `item_${Math.random().toString(36).substring(2, 16)}`,
            model_id: id
        };


        setItems([...items, tmp]);

        setTimeout(() => {
            state.current = tmp.name;
        }, 200);

        if (assets[id].approved === null) {
            tmp.data_url = assets[id].data_url;
            tmp.dimension = assets[id].dimension;
            tmp.model_id = id;
            setRatingModel(tmp);
        }
    };

    const remove = (id) => {
        let tmp = items.filter(item => item.name !== id)

        for (const item of tmp) {
            const object = sceneRef.current.getObjectByName(item.name);
            item.transform = {
                position: object.position.toArray(),
                rotation: object.rotation.toArray(),
                scale: object.scale.toArray()
            };
        }
        setItems(tmp);
    };



    useEffect(() => {

        const saveLocalInterval = setInterval(() => {
            saveInventoryLocal(room.id, sceneRef.current, itemsRef.current);
        }, 15000);

        const saveCloudInterval = setInterval(() => {
            saveInventoryCloud(room.id, sceneRef.current, itemsRef.current);
        }, 90000);

        const handleBeforeUnload = (event) => {
            if (sceneRef.current && itemsRef.current) {
                saveInventoryCloud(room.id, sceneRef.current, itemsRef.current);
            }
            // Optionally, you can set a message to prompt the user
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('pagehide', handleBeforeUnload);

        return () => {
            if (sceneRef.current && itemsRef.current) {
                saveInventoryLocal(room.id, sceneRef.current, itemsRef.current);
            }
            clearInterval(saveLocalInterval);
            clearInterval(saveCloudInterval);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('pagehide', handleBeforeUnload);

        };
    }, []);
    return (
        <div className="flex justify-center relative max-w-full h-full overflow-hidden">
            {ratingModel && <RatingModal onSubmit={(data) => { submitFeedback(data); setRatingModel(null); }} item={ratingModel} />}
            <div className="w-full md:w-1/2 z-10 absolute  ml-6 md:ml-0 md:mr-0">
                <Card className="w-full bg-blue-100  mt-2 rounded-r md:rounded-xl flex flex-row items-center justify-between">
                    <img className='select-none ml-3 w-40 sm:w-52 md:w-56 lg:w-64' src="/sobara_text.svg" alt="Logo" />
                    <RoomControls
                        state={state}
                        setMode={setMode}
                        room={room}
                        sceneRef={sceneRef}
                        itemsRef={itemsRef}
                    />
                </Card>
                <RoomSummary items={items} assets={assets} />
            </div>

            <SelectedModel items={items} assets={assets} state={state} remove={remove} />
            <SquareGrid items={assets} queued={queued} state={state} add={add} />
            <Canvas onCreated={({ camera }) => {
                camera.position.set(5, 5, 0);
            }} dpr={[1, 2]}>
                <ContactShadows
                    rotation-x={Math.PI / 2}
                    position={[0, -35, 0]}
                    opacity={0.25}
                    width={200}
                    height={200}
                    blur={1}
                    far={50}
                />
                {items.map((item) => {
                    const data_url = assets[item.model_id].data_url;
                    return (
                        <Suspense fallback={null} key={item.name}>

                            <Model
                                key={item.name}
                                url={data_url}
                                name={item.name}
                                {...item.transform}
                            />
                        </Suspense>

                    );
                })}
                <Room
                    {...convertedLayout}
                />
                <AccessScene sceneRef={sceneRef} />
                <pointLight position={[0, 3, 0]} intensity={10} />
                <hemisphereLight
                    color="#ffffff"
                    groundColor="#b9b9b9"
                    position={[-7, 25, 13]}
                    intensity={0.85}
                />
                <Controls />
            </Canvas>
        </div >
    );
};

export default RoomEditor;





