import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Editor from './pages/Editor';
import Auth from './pages/Auth';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Home from './components/Home';
import Setup from './components/setup/Main';
import Pricing from './pages/Pricing';
import Payment from './pages/Payment';
import Confirmation from './pages/Confirmation';
import InsufficientCredits from './pages/InsufficientCredits';
import SharedRoomViewer from './pages/SharedRoom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/login" element={<Auth view={"sign_in"} />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/insufficient_credits" element={<InsufficientCredits />} />
        <Route path="/room/:userId/:roomId" element={<SharedRoomViewer />} />
      </Routes>
    </Router>
  );
}

export default App;