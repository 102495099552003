import { useEffect, useCallback } from 'react'
import { motion } from 'framer-motion'
import confetti from 'canvas-confetti'
import { Card, CardContent } from "../ui/card"
import { CheckCircle } from "lucide-react"

export default function Completed({ stepFinished }) {
    const triggerConfetti = useCallback(() => {
        const count = 200
        const defaults = {
            origin: { y: 0.7 }
        }

        function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }))
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        })

        fire(0.2, {
            spread: 60,
        })

        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        })

        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        })

        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        })
    }, [])

    useEffect(() => {
        stepFinished()
        // Delay the confetti slightly to ensure the component is fully rendered
        const timer = setTimeout(() => {
            triggerConfetti()
        }, 100)

        return () => clearTimeout(timer)
    }, [stepFinished, triggerConfetti])

    return (
        <Card className="w-full max-w-md mx-auto overflow-hidden border-none shadow-none">
            <CardContent className="p-6 space-y-6">
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                    className="flex justify-center"
                >
                    <CheckCircle className="w-24 h-24 text-green-500" />
                </motion.div>

                <motion.h2
                    className="text-3xl font-bold text-center text-primary"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                >
                    Woohoo! You Did It!
                </motion.h2>

                <motion.p
                    className="text-center text-lg text-muted-foreground"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                >
                    You're all set to start your amazing journey with our service.
                    Get ready for an awesome experience!
                </motion.p>


                <motion.p
                    className="text-center text-lg text-muted-foreground"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.9 }}
                >
                    Click <strong>"Finish"</strong> to explore our amazing packages!
                </motion.p>
            </CardContent>
        </Card>
    )
}