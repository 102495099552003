/*global chrome*/
import React, { useState, useRef, useEffect } from 'react';
import { Button } from "../ui/button";
import { ChevronLeft, ChevronRight, Star, ShoppingCart, Heart, Truck, CreditCard, ZoomIn, Menu, Search, User } from 'lucide-react';
import { sendJWT } from '../../lib/misc';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs"
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog"
import Steps from './Steps';

const products = [
    {
        src: "/armchair.png",
        name: "Matching Armchair",
        price: 398.99
    },
    {
        src: "/coffee_table.png",
        name: "Coffee Table",
        price: 200.00
    }
];

const dimensions = {
    metric: {
        length: "Length: 213 cm",
        width: "Width: 91 cm",
        height: "Height: 81 cm",
        seatHeight: "Seat Height: 46 cm",
        seatDepth: "Seat Depth: 58 cm"
    },
    imperial: {
        length: "Length: 84 inches",
        width: "Width: 36 inches",
        height: "Height: 32 inches",
        seatHeight: "Seat Height: 18 inches",
        seatDepth: "Seat Depth: 23 inches"
    }
};

const productImages = [
    "https://www.mcdn.net/images/products/918c0cd6-1b7b-11ee-aae0-42010a04e00d-a38.png?width=812&height=597&output=webp",
    "https://www.mcdn.net/images/products/2f314b68-21b2-11ee-ba42-42010a04e00d-623.png?width=812&height=597&output=webp",
    "https://www.mcdn.net/images/products/3a61102a-211e-11ee-a684-42010a04e00d-62c.png?width=812&height=597&output=webp",
];


export function UseExtension({ stepFinished, setBlur }) {
    const port = useRef(null);
    const [step, setStepIndex] = useState(0);

    const steps = [
        {
            title: 'Bookmark Chrome extension',
            description: 'Bookmark the Chrome extension for easy access. This will allow you to quickly open the extension whenever needed without searching for it in the extensions menu.',
            videoSrc: 'path/to/how_to_pin_extension.mp4'
        },
        {
            title: 'Press Chrome extension button',
            description: 'Press the button of the <strong>Chrome extension</strong> to activate it. This will initiate the process of capturing product details from the website you are browsing.',
            videoSrc: 'path/to/how_to_click_extension.mp4'
        },
        {
            title: 'Select product image',
            description: 'Choose an image that clearly shows the product against a <strong>plain background</strong>. This ensures that the extension can accurately detect and process the product details for 3D modeling.',
            videoSrc: 'path/to/how_to_add_image.mp4'
        },
        {
            title: 'Select dimensions text',
            description: 'Great! Start by clicking <strong>"Next"</strong> in the extension window. Then, locate and highlight the text specifying the <strong>product dimensions</strong>. This information is essential for accurately generating the 3D representation in your design.',
            videoSrc: 'path/to/how_to_add_text.mp4'
        },
        {
            title: 'Upload the data',
            description: 'Upload the collected data to the server. The <strong>product image</strong> and its <strong>dimensions</strong> will be stored and used to generate a scaled 3D model in your room design.',
            videoSrc: 'path/to/how_to_add_text.mp4'
        },
        {
            title: "All Set!",
            description: "We’ve got it from here. On to the next step!"
        }
    ];

    useEffect(() => {
        if (chrome.runtime !== undefined) {
            port.current = chrome.runtime.connect(process.env.REACT_APP_CHROME_EXTENSION_ID);
        }

        if (port.current) {
            sendJWT(port.current);

            port.current.postMessage({
                action: "checkPinned",
            });


            port.current.onMessage.addListener((request) => {

                if (request.action === "extensionPinned") {
                    setStepIndex(prevStepIndex => prevStepIndex === 0 ? 1 : prevStepIndex);
                }

                if (request.action === "extensionClicked") {
                    setStepIndex(prevStepIndex => prevStepIndex === 1 ? 2 : prevStepIndex);
                }

                if (request.action === "imageClicked") {
                    if (request.imageSrc === productImages[2]) {
                        setStepIndex(prevStepIndex => prevStepIndex === 2 ? 3 : prevStepIndex);
                    }
                }




                if (request.action === "textEdited") {
                    console.log(request.text);
                    if (["length", "width", "height"].every(dimension => request.text.includes(dimensions["imperial"][dimension])) || ["length", "width", "height"].every(dimension => request.text.includes(dimensions["metric"][dimension]))) {
                        setStepIndex(prevStepIndex => prevStepIndex === 3 ? 4 : prevStepIndex);
                    }
                }

                if (request.action === "dataUploaded") {
                    setStepIndex(prevStepIndex => prevStepIndex === 4 ? 5 : prevStepIndex);
                    stepFinished();
                    port.current.disconnect();
                }
            });
        }
    }, []);


    useEffect(() => {
        if (setBlur && step > 1) {
            setTimeout(() => {
                setBlur(false)
            }, 500);
        }
    }, [step]);

    return <Steps stepIndex={step} steps={steps} />

}



export function Website() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [measurementSystem, setMeasurementSystem] = useState('metric');

    useEffect(() => {
        setMeasurementSystem(localStorage.getItem('measurementSystem'));
    }, []);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % productImages.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + productImages.length) % productImages.length);
    };

    return (
        <div className="flex flex-col ">
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center py-4">
                        <div className="flex items-center">
                            <Button variant="ghost" size="icon">
                                <Menu className="h-6 w-6" />
                                <span className="sr-only">Open menu</span>
                            </Button>
                            <span className="ml-2 text-xl font-bold text-gray-900">LuxeFurniture</span>
                        </div>
                        <div className="flex items-center space-x-4">
                            <Button variant="ghost" size="icon">
                                <Search className="h-6 w-6" />
                                <span className="sr-only">Search</span>
                            </Button>
                            <Button variant="ghost" size="icon">
                                <User className="h-6 w-6" />
                                <span className="sr-only">User account</span>
                            </Button>
                            <Button variant="ghost" size="icon">
                                <ShoppingCart className="h-6 w-6" />
                                <span className="sr-only">Shopping cart</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </header>
            <main className="flex-grow">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
                        {/* Left column - Image gallery */}
                        <div className="flex">


                            <div className="w-full aspect-w-1 aspect-h-1">
                                <div className="relative h-96 rounded-lg overflow-hidden">
                                    <img
                                        src={productImages[currentImageIndex]}
                                        alt={`Luxe Comfort Sofa View ${currentImageIndex + 1}`}
                                        className="w-full h-full object-center object-contain"
                                    />

                                    <Button
                                        size="icon"
                                        variant="secondary"
                                        className="absolute top-1/2 left-4 transform -translate-y-1/2 w-10 h-10 rounded-full bg-white/80 hover:bg-white shadow-md"
                                        onClick={prevImage}
                                    >
                                        <ChevronLeft className="w-6 h-6" />
                                        <span className="sr-only">Previous image</span>
                                    </Button>

                                    <Button
                                        size="icon"
                                        variant="secondary"
                                        className="absolute top-1/2 right-4 transform -translate-y-1/2 w-10 h-10 rounded-full bg-white/80 hover:bg-white shadow-md"
                                        onClick={nextImage}
                                    >
                                        <ChevronRight className="w-6 h-6" />
                                        <span className="sr-only">Next image</span>
                                    </Button>

                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button
                                                size="icon"
                                                variant="secondary"
                                                className="absolute bottom-4 right-4 w-10 h-10 rounded-full bg-white/80 hover:bg-white"
                                            >
                                                <ZoomIn className="w-6 h-6" />
                                                <span className="sr-only">Zoom image</span>
                                            </Button>
                                        </DialogTrigger>
                                        <DialogContent className="max-w-5xl">
                                            <img
                                                src={productImages[currentImageIndex]}
                                                alt={`Luxe Comfort Sofa View ${currentImageIndex + 1}`}
                                                className="w-full h-auto"
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </div>
                                <div className="flex justify-center mt-8 space-x-2">
                                    {productImages.map((_, index) => (
                                        <div
                                            key={index}
                                            className={`w-4 h-4 rounded-full ${index === currentImageIndex ? 'bg-gray-500' : 'bg-gray-300'}`}
                                            onClick={() => setCurrentImageIndex(index)} // Add onClick event
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Right column - Product details */}
                        <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
                            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">Luxe Comfort Sofa</h1>

                            {/* Price */}
                            <div className="mt-3 flex items-end">
                                <p className="text-3xl text-gray-900 font-bold">$1,299.99</p>
                                <p className="text-lg text-gray-500 line-through ml-2">$1,499.99</p>
                                <p className="text-lg text-green-600 ml-2">Save $200</p>
                            </div>

                            {/* Reviews summary */}
                            <div className="mt-3">
                                <div className="flex items-center">
                                    <div className="flex items-center">
                                        {[0, 1, 2, 3, 4].map((rating) => (
                                            <Star
                                                key={rating}
                                                className={`${rating < 4 ? 'text-yellow-400' : 'text-gray-300'
                                                    } h-5 w-5 flex-shrink-0`}
                                                aria-hidden="true"
                                            />
                                        ))}
                                    </div>
                                    <p className="sr-only">4 out of 5 stars</p>
                                    <a href="#reviews" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                        Based on 117 reviews
                                    </a>
                                </div>
                            </div>

                            {/* Add to cart and wishlist */}
                            <div className="mt-10 flex sm:flex-col1">
                                <Button size="lg" className="max-w-xs flex-1 items-center justify-center">
                                    <ShoppingCart className="mr-2 h-5 w-5" aria-hidden="true" />
                                    Add to cart
                                </Button>

                                <Button
                                    type="button"
                                    size="lg"
                                    variant="outline"
                                    className="ml-4 py-3 px-3 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                                >
                                    <Heart className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                                    <span className="sr-only">Add to favorites</span>
                                </Button>
                            </div>

                            {/* Delivery and financing options */}
                            <div className="mt-6 border-t border-gray-200 pt-6">
                                <div className="flex items-center">
                                    <Truck className="h-5 w-5 text-green-500 mr-2" />
                                    <p className="text-sm text-gray-500">Free delivery on orders over $1000</p>
                                </div>
                                <div className="mt-2 flex items-center">
                                    <CreditCard className="h-5 w-5 text-blue-500 mr-2" />
                                    <p className="text-sm text-gray-500">Financing available - As low as $108/month</p>
                                </div>
                            </div>

                            {/* Product information */}
                            <Tabs defaultValue="description" className="mt-6">
                                <TabsList>
                                    <TabsTrigger value="description">Description</TabsTrigger>
                                    <TabsTrigger value="details">Details</TabsTrigger>
                                    <TabsTrigger value="dimensions">Dimensions</TabsTrigger>
                                </TabsList>
                                <TabsContent value="description">
                                    <p className="text-base text-gray-900 mt-4">
                                        Experience ultimate comfort with our Luxe Comfort Sofa. Featuring plush cushions and a sleek design,
                                        this sofa is perfect for any contemporary living space. The durable fabric and solid wood frame ensure
                                        long-lasting quality and style. Whether you're entertaining guests or enjoying a quiet evening at home,
                                        this sofa provides the perfect blend of comfort and elegance.
                                    </p>
                                </TabsContent>
                                <TabsContent value="details">
                                    <ul className="list-disc list-inside text-base text-gray-900 mt-4">
                                        <li>High-resilience foam cushions for superior comfort</li>
                                        <li>Stain-resistant and easy-to-clean upholstery</li>
                                        <li>Solid wood frame and legs for durability</li>
                                        <li>Available in multiple colors and materials</li>
                                        <li>Removable and reversible seat cushions</li>
                                    </ul>
                                </TabsContent>
                                <TabsContent value="dimensions">
                                    <ul className="list-disc list-inside text-base text-gray-900 mt-4">
                                        {Object.values(dimensions[measurementSystem]).map((value, index) => (
                                            <li key={index}>{value}</li>
                                        ))}
                                    </ul>
                                </TabsContent>
                            </Tabs>
                        </div>
                    </div>

                    {/* Related products */}
                    <div className="mt-16 border-t border-gray-200 pt-2">
                        <h2 className="text-2xl font-extrabold text-gray-900 mb-6">Complete the look</h2>
                        <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:gri
d-cols-4 xl:gap-x-8">
                            {products.map((product, index) => (
                                <div key={index} className="group relative">
                                    <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                                        <img
                                            src={product.src}
                                            alt={`Related Product ${index}`}
                                            className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                                        />
                                    </div>
                                    <div className="mt-4 flex justify-between">
                                        <div>
                                            <h3 className="text-sm text-gray-700">
                                                {product.name}
                                            </h3>
                                        </div>
                                        <p className="text-sm font-medium text-gray-900">${product.price.toFixed(2)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main >
        </div >
    );
}


