import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

const CheckMessage = ({ title, description }) => {
    return (
        <div className="py-12">
            <motion.div
                className="space-y-4 text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <CheckCircle className="w-16 h-16 mx-auto text-green-500" />
                <h2 className="text-2xl font-bold">{title}</h2>
                <p className="text-gray-600">{description}</p>
            </motion.div>
        </div>
    );
};

export default CheckMessage;