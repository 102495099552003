import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from '../lib/misc'

export default function Payment() {
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );

    const location = useLocation();
    const priceId = new URLSearchParams(location.search).get("price_id");

    const fetchClientSecret = useCallback(async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
            throw new Error("User not authenticated");
        }
        const userId = user.id;

        return fetch("https://www.sobara.com/api/stripe/create-checkout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ priceId, userId }),
        })
            .then((res) => res.json())
            .then((data) => data.client_secret);
    }, [priceId]);

    const options = { fetchClientSecret };

    return (
        <div id="checkout" className="relative flex flex-col justify-center min-h-screen">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <div className="relative z-10">
                    <EmbeddedCheckout />
                </div>
            </EmbeddedCheckoutProvider>
        </div>
    );
}