import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { createClient } from '@supabase/supabase-js'
import * as THREE from 'three';


export const supabase = createClient(process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_PUBLIC)


export const sendJWT = async (port) => {
    const access_token = (await supabase.auth.getSession()).data.session?.access_token
    port.postMessage({
        action: "setJWT",
        jwt: access_token,
    });
};


export function convertToMeters(dimensions) {
    if (!dimensions || typeof dimensions !== 'object') {
        throw new Error("Invalid input: dimensions must be an object.");
    }

    const { width, height, length, unit } = dimensions;

    if (typeof width !== 'number' || typeof height !== 'number' || typeof length !== 'number') {
        throw new Error("Invalid input: width, height, and length must be numbers.");
    }

    if (width < 0 || height < 0 || length < 0) {
        throw new Error("Invalid input: width, height, and length must be non-negative.");
    }

    const conversionFactors = {
        cm: 0.01,       // centimeters
        dm: 0.1,        // decimeters
        mm: 0.001,      // millimeters
        m: 1,           // meters
        in: 0.0254,     // inches
        ft: 0.3048,     // feet
        yd: 0.9144,     // yards
        inches: 0.0254, // alternative spelling
        foot: 0.3048,   // alternative spelling
        yard: 0.9144,   // alternative spelling
        meter: 1,       // alternative spelling
        meters: 1       // alternative spelling
    };

    if (!conversionFactors.hasOwnProperty(unit)) {
        throw new Error(`Unit '${unit}' is not supported for conversion.`);
    }

    const factor = conversionFactors[unit];

    return {
        width: width * factor,
        height: height * factor,
        length: length * factor,
        unit: 'm'
    };
}

export const getTransform = async (asset) => {
    const loader = new GLTFLoader();
    const url = asset.data_url;

    const loadModel = (url) => {
        return new Promise((resolve, reject) => {
            loader.load(url, (gltf) => resolve(gltf), undefined, (error) => reject(error));
        });
    };

    const gltf = await loadModel(url);
    const bb = new THREE.Box3().setFromObject(gltf.scene);

    const size = bb.getSize(new THREE.Vector3());
    const dimension = convertToMeters(asset.dimension);
    const scale = [
        dimension.length / size.x,
        dimension.height / size.y,
        dimension.width / size.z
    ];

    const y = bb.min.y + (size.y / 2) * (1 - scale[1]);

    return {
        position: [0, -y, 0],
        rotation: [0, 0, 0],
        scale: scale
    };
};

const extractItems = (scene, added) => {
    if (!scene) return null;

    let tmpAdded = [...added];
    for (const item of tmpAdded) {
        const object = scene.getObjectByName(item.name);
        item.transform = {
            position: object.position.toArray(),
            rotation: object.rotation.toArray(),
            scale: object.scale.toArray()
        };
    }

    return tmpAdded;
};

export const saveInventoryLocal = (roomId, scene, added) => {
    const items = extractItems(scene, added);
    const inventory = { items, updated: Math.floor(new Date().getTime() / 1000) };
    localStorage.setItem('items:' + roomId, JSON.stringify(inventory));
    return inventory
};

export const saveInventoryCloud = async (roomId, scene, added) => {
    const inventory = saveInventoryLocal(roomId, scene, added);

    const { error } = await supabase
        .from('room_inventory')
        .update({ inventory: inventory })
        .eq('room_id', roomId);

    if (error) console.error('Error sending room data:', error);

};

export const getLatestItems = (room) => {
    const localStorageData = JSON.parse(localStorage.getItem(`items:${room.id}`)) || {};
    const { items: propItems = [], updated: propUpdated = 0 } = room.inventory;
    const { items: localItems = [], updated: localStorageUpdated = 0 } = localStorageData;
    const mostUpToDateItems = localStorageUpdated > propUpdated ? localItems : propItems;

    return mostUpToDateItems;
};


export async function shareRoom(roomId, scene, added) {
    await saveInventoryCloud(roomId, scene, added);

    const url = 'https://www.sobara.com/api/share-room';
    const jwtToken = (await supabase.auth.getSession()).data.session?.access_token

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({ roomId })
    });

    const result = await response.json();

    if (response.ok) {
        console.log('Room data shared successfully:', result);
    } else {
        console.error('Error sharing room data:', result);
    }
}
