import { Clone } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio';
import { useThree, useLoader, useFrame } from '@react-three/fiber';
import { OrbitControls, TransformControls, useCursor } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import * as THREE from 'three';

export const Room = ({ width, /*height,*/ length, id }) => {
    return (
        <>
            <mesh position={[0, 0, 0]} name={id}>
                <boxGeometry attach="geometry" args={[width, 0, length]} />
                <meshStandardMaterial attach="material" color="lightgrey" />
            </mesh>
        </>
    );
};



const modes = ['translate', 'rotate'];
export const state = proxy({ current: null, mode: 0 });


export const TrackModel = ({
    url,
    position,
    rotation,
    scale,
    targetPosition,
    targetRotation,
    onReachTarget,
    name,
}) => {
    const loadedGltf = useLoader(GLTFLoader, url);
    const gltf = useMemo(() => loadedGltf, [loadedGltf]);
    const modelRef = useRef();
    const planeRef = useRef();
    const hasReachedTarget = useRef(false);
    const snap = useSnapshot(state);
    const [hide, setHide] = useState(false);
    const consecutiveFrames = useRef(0);

    const [modelSize, setModelSize] = useState([1, 1]); // Default siz
    const margin = 0.25; // Margin around the model


    useEffect(() => {
        if (gltf.scene) {
            const bb = new THREE.Box3().setFromObject(gltf.scene);
            const size = bb.getSize(new THREE.Vector3());
            const widthAndDepth = [size.x, size.z]; // Width (x) and depth (z) without scaling
            setModelSize(widthAndDepth);
        }
    }, [gltf]);

    useFrame(() => {
        if (modelRef.current && planeRef.current && !hasReachedTarget.current) {
            // Update matrices
            modelRef.current.updateMatrixWorld();
            planeRef.current.updateMatrixWorld();

            // Get bounding boxes
            const modelBB = new THREE.Box3().setFromObject(modelRef.current);
            const planeBB = new THREE.Box3().setFromObject(planeRef.current);

            // Expand plane's bounding box in Y axis to cover all heights
            planeBB.min.y = -1;
            planeBB.max.y = 5;

            // Check for containment
            const isContained =
                modelBB.min.x >= planeBB.min.x &&
                modelBB.max.x <= planeBB.max.x &&
                modelBB.min.z >= planeBB.min.z &&
                modelBB.max.z <= planeBB.max.z;

            if (isContained)
                consecutiveFrames.current += 1;
            else
                consecutiveFrames.current = 0;

            if (consecutiveFrames.current >= 15 && !hide) {
                hasReachedTarget.current = true;
                setHide(true);
                if (onReachTarget) onReachTarget();
            }
        }
    });
    return (
        <>
            {/* Plane at the target position */}
            <mesh visible={!hide} ref={planeRef} position={targetPosition} rotation={targetRotation}>
                <planeGeometry args={[
                    modelSize[0] * scale[0] + margin * 2,
                    modelSize[1] * scale[2] + margin * 2,
                ]} />
                <meshStandardMaterial color="red" />
            </mesh>

            {/* The model */}
            <group
                ref={modelRef}
                position={position}
                rotation={rotation}
                scale={scale}
                name={name}
                onClick={(e) => {
                    e.stopPropagation();
                    state.current = name; // Set the current selected object
                }}
                onPointerMissed={(e) => {
                    if (e.type === 'click') {
                        state.current = null; // Deselect if clicked outside
                    }
                }}
                onContextMenu={(e) => {
                    if (snap.current === name) {
                        e.stopPropagation();
                        state.mode = (snap.mode + 1) % modes.length; // Cycle through modes
                    }
                }}
            >
                <Clone object={gltf.scene} />
            </group>
        </>
    );
};

export const Model = ({ url, name, position, rotation, scale }) => {
    const snap = useSnapshot(state);
    const loadedGltf = useLoader(GLTFLoader, url);
    const gltf = useMemo(() => loadedGltf, [loadedGltf]);
    const [hovered, setHovered] = useState(false);
    useCursor(hovered)
    return (
        <group
            onClick={(e) => { e.stopPropagation(); state.current = name; }}
            onPointerMissed={(e) => { if (e.type === 'click') { state.current = null; } }}
            onContextMenu={(e) => { if (snap.current === name) { e.stopPropagation(); state.mode = (snap.mode + 1) % modes.length; } }}
            onPointerOver={(e) => { e.stopPropagation(); setHovered(true); }}
            onPointerOut={() => { setHovered(false); }}
            position={position}
            rotation={rotation}
            scale={scale}
            name={name}>
            <Clone
                object={gltf.scene}
            />
        </group>
    );
};

export const Controls = ({ config = {
    'translate': { showX: true, showY: true, showZ: true, translationSnap: 0.05 },
    'rotate': { showX: true, showY: true, showZ: true, rotationSnap: Math.PI / 18 },
} }) => {
    const snap = useSnapshot(state);
    const scene = useThree((state) => state.scene);

    return (
        <>
            {snap.current && <TransformControls
                object={scene.getObjectByName(snap.current)}
                mode={modes[snap.mode]}
                showX={config[modes[snap.mode]].showX}
                showY={config[modes[snap.mode]].showY}
                showZ={config[modes[snap.mode]].showZ}
                translationSnap={config[modes[snap.mode]].translationSnap}
                rotationSnap={config[modes[snap.mode]].rotationSnap}
            />}
            <OrbitControls target={[0, 0, 0]} makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} />
        </>
    );
};
