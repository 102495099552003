import { useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from "../components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { Home, Zap, Info, Package } from "lucide-react"
import { useNavigate } from 'react-router-dom';

const packages = [
    { name: "Studio", rooms: 3, credits: 1000, price: 9.99, color: "from-pink-500 to-rose-500", price_id: process.env.REACT_APP_STUDIO_PRICE_ID },
    { name: "Condo", rooms: 5, credits: 2200, price: 19.99, color: "from-purple-500 to-indigo-500", price_id: process.env.REACT_APP_CONDO_PRICE_ID },
    { name: "Mansion", rooms: 7, credits: 4400, price: 39.99, color: "from-blue-500 to-cyan-500", price_id: process.env.REACT_APP_MANSION_PRICE_ID },
]

const RoomVisualizer = ({ count }) => (
    <div className="flex flex-wrap gap-1 mb-2">
        {Array.from({ length: count }).map((_, i) => (
            <div key={i} className="w-4 h-4 bg-gray-800 rounded-sm"></div>
        ))}
    </div>
)

const CreditItemVisualizer = ({ credits }) => {
    const itemCount = Math.floor(credits / 100)

    return (
        <div className="space-y-4">
            <p className="text-xl mb-2">✨ {credits} Design Credits</p>
            <p className="text-lg mb-2">Add up to {itemCount} items:</p>
            <div className="grid grid-cols-10 gap-2">
                {Array.from({ length: itemCount }).map((_, i) => (
                    <Package key={i} className="w-6 h-6 text-blue-500" />
                ))}
            </div>
            <p className="text-sm text-gray-600">
                Each <Package className="w-4 h-4 inline-block text-blue-500" /> represents 100 credits
            </p>
        </div>
    )
}

export default function Pricing() {
    const [hoveredIndex, setHoveredIndex] = useState(null)
    const navigate = useNavigate()

    return (
        <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-5xl font-extrabold text-center mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                    Design Your Dream
                </h1>
                <p className="text-xl text-center text-gray-600 mb-12">Choose a plan that matches your vision</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                    {packages.map((pkg, index) => (
                        <motion.div
                            key={index}
                            className="relative"
                            initial={{ y: 50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            onHoverStart={() => setHoveredIndex(index)}
                            onHoverEnd={() => setHoveredIndex(null)}
                        >
                            <div className="relative">
                                <div className={`absolute inset-0 bg-gradient-to-br ${pkg.color} rounded-lg transform -rotate-6 transition-all duration-300 ${hoveredIndex === index ? 'scale-105' : ''}`} style={{ zIndex: 0 }}></div>
                                <div className="relative bg-white p-8 rounded-lg shadow-xl transform transition-all duration-300 hover:rotate-2 z-1">
                                    <h2 className="text-3xl font-bold mb-4 text-gray-800">{pkg.name}</h2>
                                    <p className="text-5xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                                        ${pkg.price}
                                        <span className="text-base font-normal text-gray-600">/mo</span>
                                    </p>
                                    <div className="mb-8 space-y-4">
                                        <div>
                                            <p className="text-xl mb-2">🏠 {pkg.rooms} Rooms</p>
                                            <RoomVisualizer count={pkg.rooms} />
                                        </div>
                                        <CreditItemVisualizer credits={pkg.credits} />
                                    </div>
                                    <Button onClick={() => navigate(`/payment?price_id=${pkg.price_id}`)} className="w-full text-lg py-6 bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 text-white font-semibold rounded-full transition-all duration-300 transform hover:scale-105">
                                        Start Designing
                                    </Button>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
                <Card className="bg-white shadow-xl rounded-lg overflow-hidden">
                    <CardHeader className="bg-gradient-to-r from-purple-500 to-pink-500 p-6">
                        <CardTitle className="text-2xl font-bold text-white flex items-center">
                            <Info className="mr-2" /> Features
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="p-6">
                        <div className="grid md:grid-cols-2 gap-6">
                            <div className="space-y-4">
                                <h3 className="text-xl font-semibold flex items-center">
                                    <Home className="mr-2" /> Rooms
                                </h3>
                                <p className="text-gray-600">
                                    The number of rooms indicates how many distinct spaces you can design within your subscription. Each room can be individually styled and furnished, allowing you to create a cohesive look throughout your home or focus on specific areas.
                                </p>
                            </div>
                            <div className="space-y-4">
                                <h3 className="text-xl font-semibold flex items-center">
                                    <Zap className="mr-2" /> Design Credits
                                </h3>
                                <p className="text-gray-600">
                                    Design credits are used to add items to your inventory. Each item costs 100 credits to add to your inventory, after which you can place it as many times as you'd like in any room. The package icons show the number of items you can add with your credits—each icon represents one unique item available for unlimited use in your designs.
                                </p>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}