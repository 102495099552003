import { Plus } from 'lucide-react';
import { supabase } from '../lib/misc';

const RoomSelectionButton = ({ room, onClick }) => (
    <button
        onClick={onClick}
        className="w-full aspect-square m-2 flex flex-col justify-center items-center text-2xl border-2 border-gray-300 rounded-lg bg-gray-100 cursor-pointer"
    >
        {room ? room.name : <Plus size={48} />}
    </button>
);

const RoomSelection = ({ rooms, user, onRoomSelect, onCreateRoom }) => (
    <div className="relative flex justify-center items-center min-h-screen p-4 bg-gray-50">
        <button className="absolute top-4 left-4 font-bold text-gray-800" onClick={async () => { await supabase.auth.signOut() }}> Sign out</button>
        <div className="absolute top-4 right-4 font-bold text-gray-800">
            Credits: {user.credits}
        </div>
        <div className="w-full max-w-1xl mx-auto">
            <h1 className="text-3xl font-bold text-center text-gray-800 my-4 tracking-wide">
                Select or Create a Room
            </h1>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-items-center max-w-3xl mx-auto">
                {Array.from({ length: user.rooms }).map((_, index) => (
                    <RoomSelectionButton
                        key={index}
                        room={rooms[index] || null}
                        onClick={() =>
                            rooms[index] ? onRoomSelect(rooms[index]) : onCreateRoom()
                        }
                    />
                ))}
            </div>
        </div>
    </div>
);

export default RoomSelection;