import React from 'react';
import { saveInventoryLocal, shareRoom } from '../lib/misc';
import { Save, Wrench, DoorOpen, Share2 } from 'lucide-react';
import { Button } from './ui/button'


const RoomControls = ({ state, setMode, room, sceneRef, itemsRef }) => {
    return (
        <div className="flex items-center space-x-2 pr-3 py-2">
            <Button variant="outline" size="icon" onClick={() => { state.current = null; setMode('create-room'); }}>
                <Wrench className="h-4 w-4" />
            </Button>
            <Button variant="outline" size="icon" onClick={() => { state.current = null; setMode('select-room'); }}>
                <DoorOpen className="h-4 w-4" />
            </Button>
            <Button variant="outline" size="icon" onClick={() => saveInventoryLocal(room.id, sceneRef.current, itemsRef.current)}>
                <Save className="h-4 w-4" />
            </Button>
            <Button variant="outline" size="icon" onClick={() => shareRoom(room.id, sceneRef.current, itemsRef.current)}>
                <Share2 className="h-4 w-4" />
            </Button>
        </div>
    );
};

export default RoomControls;