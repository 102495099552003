import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { ContactShadows } from '@react-three/drei';
import { Model, Room, Controls, state } from '../components/Models';
import '../styles/RoomView.css';
import { getTransform, convertToMeters } from '../lib/misc';
import { useParams } from 'react-router-dom';
import SquareGrid from '../components/SquareGrid';
import { SelectedModel } from '../components/SelectedModel';
import RoomSummary from '../components/RoomSummary';
import { Card } from '../components/ui/card'
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button'

const AccessScene = ({ sceneRef }) => {
    const { scene } = useThree();
    useEffect(() => {
        sceneRef.current = scene;
    }, [scene, sceneRef]);
    return null;
};


const SharedRoomViewer = () => {
    const { userId, roomId } = useParams();
    const [roomData, setRoomData] = useState(null);
    const [items, setItems] = useState([]);
    const [convertedLayout, setConvertedLayout] = useState(null);
    const [assets, setAssets] = useState({});
    const sceneRef = useRef();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchRoomData = async () => {
            try {
                const response = await fetch(`https://rooms.sobara.com/${userId}/${roomId}.json`);
                const data = await response.json();
                console.log('Room data:', data);
                setItems(data.inventory.items);
                Object.keys(data.assets).forEach(key => {
                    const item = data.assets[key];
                    item.img_url = `https://thumbnails.sobara.com/${userId}/${key}.jpg`;
                });
                console.log('Assets:', data.assets);
                setAssets(data.assets);
                setConvertedLayout(convertToMeters(data.layout));
                setRoomData(data);

            } catch (error) {
                console.error('Error fetching room data:', error);
            }
        };

        fetchRoomData();
    }, [userId, roomId]);

    const add = async (id, state) => {
        const tmp = {
            transform: await getTransform(assets[id]),
            name: `item_${Math.random().toString(36).substring(2, 16)}`,
            model_id: id
        };


        setItems([...items, tmp]);

        setTimeout(() => {
            state.current = tmp.name;
        }, 200);

    };

    const remove = (id) => {
        let tmp = items.filter(item => item.name !== id)

        for (const item of tmp) {
            const object = sceneRef.current.getObjectByName(item.name);
            item.transform = {
                position: object.position.toArray(),
                rotation: object.rotation.toArray(),
                scale: object.scale.toArray()
            };
        }
        setItems(tmp);
    };


    if (!roomData) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                </div>
            </div>
        );
    }



    return (
        <div className="flex justify-center relative max-w-full h-full overflow-hidden">

            <div className="w-full md:w-1/2 z-10 absolute  ml-6 md:ml-0 md:mr-0">
                <Card className="w-full bg-blue-100  mt-2 rounded-r md:rounded-xl flex flex-row items-center justify-between pr-6 py-2">
                    <img className='select-none ml-3 w-40 sm:w-52 md:w-56 lg:w-64' src="/sobara_text.svg" alt="Logo" />
                    <Button onClick={() => navigate('/setup?step=SignUp')} size="sm">
                        Get Started
                    </Button>
                </Card>
                <RoomSummary items={items} assets={assets} />
            </div>
            <SelectedModel items={items} assets={assets} state={state} remove={remove} />
            <SquareGrid items={assets} queued={[]} state={state} add={add} />
            <Canvas onCreated={({ camera }) => {
                camera.position.set(5, 5, 0);
            }} dpr={[1, 2]}>
                <AccessScene sceneRef={sceneRef} />

                <ContactShadows
                    rotation-x={Math.PI / 2}
                    position={[0, -35, 0]}
                    opacity={0.25}
                    width={200}
                    height={200}
                    blur={1}
                    far={50}
                />
                {items.map((item) => {
                    const data_url = "https://models.sobara.com/" + userId + "/" + item.model_id + ".glb";
                    return (
                        <Suspense fallback={null} key={item.name}>
                            <Model
                                key={item.name}
                                url={data_url}
                                name={item.name}
                                {...item.transform}
                            />
                        </Suspense>
                    );
                })}
                <Room {...convertedLayout} />
                <pointLight position={[0, 3, 0]} intensity={10} />
                <hemisphereLight
                    color="#ffffff"
                    groundColor="#b9b9b9"
                    position={[-7, 25, 13]}
                    intensity={0.85}
                />
                <Controls />
            </Canvas>
        </div >
    );
};

export default SharedRoomViewer;