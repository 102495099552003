import { Button } from "./ui/button"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "./ui/dialog"
import { ScrollArea } from "./ui/scroll-area"
import { AlertCircle, ExternalLink } from "lucide-react"


export default function FailedModal({ failedModels, setFailedModels }) {

    return (
        <Dialog open={true}>
            <DialogTrigger asChild>
                <Button variant="outline">View Failed Generations</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Failed Generations</DialogTitle>
                </DialogHeader>
                <div className="mb-4 p-4 bg-red-100 border border-red-400 rounded-md">
                    <p className="text-red-700 font-medium">
                        The following generations have failed. Your credits have been refunded for these items.
                    </p>
                </div>
                <ScrollArea className="h-[400px] w-full rounded-md border p-4">
                    {failedModels.map((gen) => (
                        <div key={gen.id} className="mb-6 last:mb-0 pb-6 border-b last:border-b-0">
                            <div className="flex items-start space-x-4">
                                <div className="flex-shrink-0 w-24 h-24 bg-gray-200 rounded-md overflow-hidden">
                                    <img src={gen.img_url} alt={gen.name || "Failed generation"} className="w-full h-full object-cover" />
                                </div>
                                <div className="flex-grow">
                                    <h3 className="text-lg font-medium">{gen.name || "Unnamed Item"}</h3>
                                    <div className="mt-2 space-y-1">
                                        <div className="flex items-center space-x-2">
                                            <span className="text-sm text-muted-foreground">Source:</span>
                                            <a href={gen.src_url} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:underline">
                                                {gen.src_url.substring(0, 30) + "..."}
                                                <ExternalLink className="ml-1 inline h-3 w-3" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="flex items-center space-x-1 text-sm font-medium text-red-600">
                                        <AlertCircle className="h-4 w-4" />
                                        <span>Failed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </ScrollArea>
                <Button onClick={() => setFailedModels([])} className="mt-4">Close</Button>
            </DialogContent>
        </Dialog>
    )
}