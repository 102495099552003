import React, { useEffect, useState } from 'react'
import { supabase } from '../../lib/misc'
import { Auth as SupabaseAuth } from '@supabase/auth-ui-react'
import { ThemeSupa } from "@supabase/auth-ui-shared"
import Check from './Check'

export default function SignUp({ stepFinished }) {
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        const checkUserSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                setSignedIn(true);
                stepFinished();
            }
        };

        checkUserSession();

        const { data: authListener } = supabase.auth.onAuthStateChange(async (event) => {
            if (event === "SIGNED_IN") {
                setSignedIn(true);
                stepFinished();
            }
        });

        return () => {
            authListener.subscription.unsubscribe();
        };
    }, []);

    if (signedIn) {
        return <Check title="Successfully Signed Up!" description="Your account has been created and you're now signed in." />
    }

    return (
        <div className="space-y-4">
            <div className="w-4/5 max-w-md mx-auto my-8">
                <SupabaseAuth
                    supabaseClient={supabase}
                    providers={[]}
                    appearance={{
                        theme: ThemeSupa,
                        variables: {
                            default: {
                            },
                        },
                    }}
                    showLinks={false}
                    view="sign_up"
                    redirectTo={`https://www.sobara.com/setup?step=SignUp`}
                />
            </div>
        </div>
    )
}