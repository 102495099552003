import React, { useState } from 'react';
import { ExternalLink, AlertCircle, Plus, Loader } from 'lucide-react';
import styles from '../styles/SquareGrid.module.css';
import { useSnapshot } from 'valtio';
import { state } from './Models';

const Square = ({ item, id, add }) => {
    return (
        <div key={id} className={styles.square}>
            <div className={styles.itemInfo}>
                {item.img_url ? (
                    <img
                        src={item.img_url}
                        alt={item.name || 'Item icon'}
                        className={styles.itemIcon}
                        draggable="false"
                    />
                ) : (
                    <div className={styles.iconPlaceholder}>
                        <AlertCircle className={styles.icon} />
                    </div>
                )}
                {item.approved === null && (
                    <div className={styles.newOverlay}>
                        <span className={styles.newText}>New</span>
                    </div>
                )}
            </div>

            <div className={styles.buttonContainer}>

                <button onClick={() => { window.open(item.src_url) }} className={styles.visibilityToggle}>
                    < ExternalLink size={15} strokeWidth={1.5} />
                </button>

                <button onClick={() => { add(id, state) }} className={styles.visibilityToggle}>
                    <Plus size={15} strokeWidth={1.5} />
                </button>
            </div>
        </div>
    );
};


const QueuedSquare = ({ item, index }) => {
    return (
        <div key={index} className={styles.square}>
            <div className={styles.itemInfo}>
                <img
                    src={item.img_url}
                    alt={item.name || 'Queued item icon'}
                    className={styles.itemIcon}
                    draggable="false"
                />
                <div className={styles.loadingOverlay}>
                    <Loader className={styles.loadingSpinner} />
                </div>
            </div>
        </div>
    );
};

const SquareGrid = ({ items, add, queued, state }) => {
    const snap = useSnapshot(state);
    return (
        <div className={`${styles.container} select-none`} style={{ display: snap.current == null ? 'block' : 'none' }}>
            <div className={styles.grid}>
                {queued.map((item, index) => (
                    <QueuedSquare key={index} item={item} index={index} />
                ))}
                {Object.keys(items).map((id) => {
                    const item = items[id];
                    return (
                        <Square key={id} item={item} id={id} add={add} s />
                    );
                })}
            </div>
        </div>
    );
};

export default SquareGrid;