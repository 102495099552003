import React, { useEffect, useState } from 'react';

import { Package } from 'lucide-react';
import { Button } from './ui/button'
import { useSpring, useTransform, motion } from 'framer-motion';

const AnimatedPrice = ({ price }) => {
    const [prevPrice, setPrevPrice] = useState(0); // Initialize to 0 for animation
    const animatedPrice = useSpring(prevPrice, { stiffness: 100, damping: 30 });

    useEffect(() => {
        if (price !== prevPrice) {
            animatedPrice.set(price);
            setPrevPrice(price);
        }
    }, [price, prevPrice, animatedPrice]);

    const roundedPrice = useTransform(animatedPrice, (latest) => Math.round(latest));

    return (
        <div className="flex items-center space-x-2 min-w-100">
            <motion.span className="text-lg font-bold text-green-500 select-none">
                $<motion.span>{roundedPrice}</motion.span>
            </motion.span>
        </div>
    );
};


const RoomSummary = ({ items, assets }) => {
    console.log(items, assets);

    return (
        <div className="mt-2 inline-flex items-center space-x-4 bg-white shadow-lg p-2 rounded-lg relative border border-gray-300 hover:shadow-xl transition-shadow duration-300">
            <Button variant="outline" size="icon" className="relative">
                <Package className="w-4 h-4" />
                <span className="select-none absolute bottom-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-500 transform translate-x-1/4 translate-y-1/4">
                    {items.length}
                </span>
            </Button>
            <AnimatedPrice
                price={
                    items.reduce((acc, item) => acc + assets[item.model_id].price / (assets[item.model_id].currency === 'SEK' ? 10 : 1), 0)

                }
            />
        </div>
    );
};

export default RoomSummary;