import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Progress } from "../ui/progress";
import { CardContent } from "../ui/card"
import Check from './Check';

const Steps = ({ stepIndex, steps }) => {

    if (stepIndex === steps.length - 1) {
        return (
            <Check
                title={steps[stepIndex].title}
                description={steps[stepIndex].description}
            />
        );
    }


    return (
        <div className="space-y-4 md:space-y-6">
            <CardContent className="p-0 pl-4">
                <Progress
                    value={Math.max((stepIndex) / (steps.length - 1) * 100, 1)}
                    className="mb-2 md:mb-4"
                />
                <AnimatePresence mode="wait">
                    <motion.div
                        key={stepIndex}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <h3 className="text-base md:text-xl font-semibold mb-1 md:mb-2">
                            Step {stepIndex + 1}: {steps[stepIndex].title}
                        </h3>
                        <div />
                        <p dangerouslySetInnerHTML={{ __html: steps[stepIndex].description }} className="text-xs md:text-base text-gray-600" />
                        <div className="space-y-2 md:space-y-4">
                            <video
                                className="w-full h-auto"
                                muted
                                autoPlay
                                loop
                            >
                                <source
                                    src={steps[stepIndex].videoSrc}
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </CardContent>
        </div>
    );
};

export default Steps;