import React, { useEffect, useState, Suspense, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { ContactShadows, OrbitControls } from '@react-three/drei';
import { Model, Room, Controls, state, TrackModel } from '../Models';
import SquareGrid from '../SquareGrid';
import { SelectedModel } from '../SelectedModel';
import '../../styles/RoomView.css';
import { getTransform } from '../../lib/misc';
import Steps from './Steps';

export function Showcase({ stepFinished, step }) {
    useEffect(() => {
        if (step === 7) {
            stepFinished();
        }
    }, [step, stepFinished]);

    const steps = [
        {
            title: "Camera Movements",
            description: "Your item that you want to add is queued as we created a digital twin of it, usually it takes 1-2 minutes, while we wait let's try the camera movements. Start with pressing the <strong>left mouse button</strong> and move the mouse to rotate the camera.",
            videoSrc: "path/to/video1.mp4"
        },
        {
            title: "Move Camera",
            description: "Good job! Now try to move the camera back and forward by holding down the <strong>shift key</strong> while pressing down the <strong>left mouse button</strong> and moving the mouse.",
            videoSrc: "path/to/video2.mp4"
        },
        {
            title: "Zoom Camera",
            description: "Great job! Lastly try to <strong>zoom in and out</strong> by scrolling the mouse wheel or pinching on the trackpad.",
            videoSrc: "path/to/video3.mp4"
        },
        {
            title: "Add Item",
            description: "At the bottom you can see your added item with the overlay <strong>'NEW'</strong>, hover over it and press the <strong>+</strong> button to add it to the room:",
            videoSrc: "path/to/video3.mp4"
        },
        {
            title: "Select Item",
            description: "Good job! Now select the item by <strong>left clicking</strong> on it and you will see colored arrows, use them to move the item to the red zone.",
            videoSrc: "path/to/video4.mp4"
        },
        {
            title: "Rotate Item",
            description: "Good job! Look, we’ve added a carpet, and we’ll use it to learn how to rotate items. Go ahead and add it to the room. When the item is selected, you can <strong>right-click</strong> or press the <strong>Rotate</strong> option at the bottom of the screen to switch to rotation controls. Use the colored hoops to rotate the item, then position it in the red area.",
            videoSrc: "path/to/video5.mp4"
        },
        {
            title: "Remove Item",
            description: "Woops, we accidentally added a table, let's remove it by <strong>left clicking</strong> on it and pressing the <strong>'Store Away'</strong> button.",
            videoSrc: "path/to/video5.mp4"
        },
        {
            title: "Time to Get Creative!",
            description: "You've mastered the basics needed to design your dream living space, feel free to add the other items, and when you feel like it go to the next step."
        }
    ];

    return <Steps stepIndex={step} steps={steps} />
}

function CameraControls({ setStep }) {
    const controlsRef = useRef();
    const [isShiftPressed, setIsShiftPressed] = useState(false);
    const [isZooming, setIsZooming] = useState(false);

    const [start, setStart] = useState(false);

    const { camera, gl } = useThree();
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Shift') {
                setIsShiftPressed(true);
            }
        };

        const handleKeyUp = (event) => {
            if (event.key === 'Shift') {
                setIsShiftPressed(false);
            }
        };

        const handleWheel = (event) => {
            setIsZooming(true);
            setTimeout(() => setIsZooming(false), 100); // Reset zooming state after a short delay
        };
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        window.addEventListener('wheel', handleWheel);

        // Clean up
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('wheel', handleWheel);

        };
    }, []);

    useEffect(() => {
        if (controlsRef.current) {
            controlsRef.current.enablePan = isShiftPressed;
        }
    }, [isShiftPressed]);


    return (
        <OrbitControls
            ref={controlsRef}
            args={[camera, gl.domElement]}
            onChange={(e) => {
                if (!start) {
                    setStart(true);
                    return;
                }
                if (isShiftPressed) {
                    setTimeout(() => {
                        setStep(prevStepIndex => prevStepIndex === 1 ? 2 : prevStepIndex);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setStep(prevStepIndex => prevStepIndex === 0 ? 1 : prevStepIndex);
                    }, 1000);
                }

                if (isZooming) {
                    setTimeout(() => {
                        setStep(prevStepIndex => prevStepIndex === 2 ? 3 : prevStepIndex);
                    }, 1000);
                }
            }}
        />
    );
}

const AccessScene = ({ sceneRef }) => {
    const { scene } = useThree();
    useEffect(() => {
        sceneRef.current = scene;
    }, [scene, sceneRef]);
    return null;
};

const RoomEditor = ({ room, assets, queued, setStep, step }) => {
    const [items, setItems] = useState([]);
    const [ReachState, onReachTarget] = useState(false);
    const [filteredIds, setFilteredIds] = useState(["carpet", "table"]);

    const [filteredAssets, setFilteredAssets] = useState({});

    useEffect(() => {
        setFilteredAssets(Object.fromEntries(
            Object.entries(assets).filter(([key, value]) => !filteredIds.includes(key))
        ));
    }, [filteredIds, assets]);


    useEffect(() => {

        if (ReachState === "sofa") {
            setStep(prevStepIndex => prevStepIndex === 4 ? 5 : prevStepIndex);
        }

        if (ReachState === "carpet") {
            setStep(prevStepIndex => prevStepIndex === 5 ? 6 : prevStepIndex);
        }
    }, [ReachState]);


    const sceneRef = useRef();
    const itemsRef = useRef(items);

    useEffect(() => {
        itemsRef.current = items;
    }, [items]);

    const add = async (id) => {
        setStep(prevStepIndex => {
            if (prevStepIndex === 3 && id === "sofa") {
                setFilteredIds(prevIds => [...prevIds, "sofa"]);
                return 4;
            }
            return prevStepIndex;
        });



        const tmp = {
            transform: await getTransform(assets[id]),
            name: `item_${Math.random().toString(36).substring(2, 16)}`,
            model_id: id,
            track: assets[id].track || false,
            target_position: assets[id].target_position || null,
            target_rotation: assets[id].target_rotation || null
        };
        setItems([...items, tmp]);
    };

    const remove = (id) => {
        const updatedItems = items.filter(item => item.name !== id);

        const removedItem = items.find(item => item.name === id);
        if (removedItem?.model_id === "table") {
            setStep(prevStepIndex => (prevStepIndex === 6 ? 7 : prevStepIndex));
        }

        const transformedItems = updatedItems.map(item => {
            const object = sceneRef.current.getObjectByName(item.name);
            return {
                ...item,
                transform: {
                    position: object.position.toArray(),
                    rotation: object.rotation.toArray(),
                    scale: object.scale.toArray(),
                },
            };
        });

        setItems(transformedItems);
    };

    useEffect(() => {
        if (step === 5) {
            if ("carpet" in assets) {
                add("carpet")
            }
        }

        if (step === 6) {
            if ("table" in assets) {
                add("table")
            }
        }

        if (step === 7) {
            setFilteredIds([]);
        }

    }, [step, assets]);


    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <SquareGrid items={filteredAssets} queued={queued} state={state} add={add} />
            <SelectedModel items={items} assets={assets} remove={remove} state={state} />
            <Canvas onCreated={({ camera }) => {
                camera.position.set(10, 5, 5);
            }} dpr={[1, 2]}>
                <Suspense fallback={null}>
                    <ContactShadows
                        rotation-x={Math.PI / 2}
                        position={[0, -35, 0]}
                        opacity={0.25}
                        width={200}
                        height={200}
                        blur={1}
                        far={50}
                    />
                    {items.map((item) => {
                        const data_url = assets[item.model_id].data_url;
                        return item.track ? (
                            <TrackModel
                                key={item.name}
                                url={data_url}
                                name={item.name}
                                {...item.transform}
                                onReachTarget={() => onReachTarget(item.model_id)}
                                targetPosition={item.target_position}
                                targetRotation={item.target_rotation}
                            />
                        ) : (
                            <Model
                                key={item.name}
                                url={data_url}
                                name={item.name}
                                {...item.transform}
                            />
                        );
                    })}
                    <Room
                        {...room.layout}
                    />
                </Suspense>
                <AccessScene sceneRef={sceneRef} />
                <pointLight position={[0, 3, 0]} intensity={10} />
                <hemisphereLight
                    color="#ffffff"
                    groundColor="#b9b9b9"
                    position={[-7, 25, 13]}
                    intensity={0.85}
                />
                <Controls config={{
                    translate: { showX: true, showY: false, showZ: true, translationSnap: 0.05 },
                    rotate: { showX: false, showY: true, showZ: false, rotationSnap: Math.PI / 18 },
                }} />
                {step < 3 && <CameraControls setStep={setStep} />}
            </Canvas>
        </div>
    );
};




export function Viewer({ setStep, step }) {



    const room = {
        id: "test",
        name: "",
        slot: 0,
        layout: {
            width: 6,
            height: 3,
            length: 5,
        },
        inventory: {
            items: [],
            updated: 1726599813,
        },
    };

    const endAssets = {
        "892c69c0-75cd-4ff4-8788-79c154d264a3": {
            "data_url": "https://pub-ad1c3057ae364f798bafc57301c802ef.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/892c69c0-75cd-4ff4-8788-79c154d264a3.glb",
            "img_url": "https://pub-63bfc0cafc7642c69f901542afb1e939.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/892c69c0-75cd-4ff4-8788-79c154d264a3.jpg",
            "src_url": "https://www.vepsalainen.com/en/se/fritz-hansen-egg-chair-wild-leather-walnut-0126000491/",
            "name": "Egg Chair",
            "new": true,
            "dimension": {
                "unit": "m",
                "width": 0.86,
                "height": 1.07,
                "length": 0.9500000000000001
            }
        },
        "ee11aed4-4da2-47a5-9024-d574745ecd0a": {
            "data_url": "https://pub-ad1c3057ae364f798bafc57301c802ef.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/ee11aed4-4da2-47a5-9024-d574745ecd0a.glb",
            "img_url": "https://pub-63bfc0cafc7642c69f901542afb1e939.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/ee11aed4-4da2-47a5-9024-d574745ecd0a.jpg",
            "src_url": "https://www.mio.se/p/astrid-tv-b%C3%A4nk-165x40x60-cm/449779?id=M2174399",
            "name": "Wooden Sideboard with Slatted Design",
            "new": true,
            "dimension": {
                "unit": "m",
                "width": 1.6500000000000001,
                "height": 0.6,
                "length": 0.4
            }
        },
        "7e0480dc-7cf4-47ad-aa6e-cc5d34436551": {
            "data_url": "https://pub-ad1c3057ae364f798bafc57301c802ef.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/7e0480dc-7cf4-47ad-aa6e-cc5d34436551.glb",
            "img_url": "https://pub-63bfc0cafc7642c69f901542afb1e939.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/7e0480dc-7cf4-47ad-aa6e-cc5d34436551.jpg",
            "src_url": "https://www.mio.se/p/ester-soffbord-110x60x50-cm/457761?id=M2185885",
            "name": "Oval Wooden Coffee Table with Lower Shelf",
            "new": true,
            "dimension": {
                "unit": "m",
                "width": 0.6,
                "height": 0.5,
                "length": 1.1
            }
        },
    }

    const initialAssets = {}
    const table = {
        "data_url": "https://pub-ad1c3057ae364f798bafc57301c802ef.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/9d9b7367-cc9e-41a1-aaf7-38e68c8b9c59.glb",
        "img_url": "https://pub-63bfc0cafc7642c69f901542afb1e939.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/9d9b7367-cc9e-41a1-aaf7-38e68c8b9c59.jpg",
        "src_url": "https://www.mio.se/p/ferris-sidobord-%C3%B8-40-h-50-cm/529228?id=M2268814",
        "name": "Modern Wooden Side Table with Slatted Base",
        "dimension": {
            "unit": "m",
            "width": 0.4,
            "height": 0.5,
            "length": 0.4
        }
    }

    const carpet = {
        "data_url": "https://pub-ad1c3057ae364f798bafc57301c802ef.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/35fd4e8b-4458-486c-ac00-bece40f52e5e.glb",
        "img_url": "https://pub-63bfc0cafc7642c69f901542afb1e939.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/35fd4e8b-4458-486c-ac00-bece40f52e5e.jpg",
        "src_url": "https://www.rugvista.se/p/big-sur-240x300-cm-gul-krambeige-stor-matta-RVD39540?utm_content=voucher-test-may-2024&googleShopping=1&gad_source=1&gclid=Cj0KCQjwwuG1BhCnARIsAFWBUC0AaAVPG1bHdC-eKyst-cL0RKHxKPCyprJaBaL3Nz8snRg-fwoGjDMaAqozEALw_wcB",
        "name": "Geometric Area Rug",
        "dimension": {
            "unit": "m",
            "width": 2.4,
            "height": 0.013000000000000001,
            "length": 3
        },
        "track": true,
        "target_position": [-1.0, 0.01, 0],
        "target_rotation": [Math.PI / 2, Math.PI, Math.PI / 2]
    }

    const sofa = {
        "data_url": "https://pub-ad1c3057ae364f798bafc57301c802ef.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/f03a4af7-bd25-4c10-bb3a-9f0c1c2ad047.glb",
        "img_url": "https://pub-63bfc0cafc7642c69f901542afb1e939.r2.dev/45e21f7c-45b8-4071-8ec2-e177badd0b95/f03a4af7-bd25-4c10-bb3a-9f0c1c2ad047.jpg",
        "src_url": "https://www.mio.se/p/madison-3-sits-soffa/534072?ck_sofaArmrest=ARMA&ck_sofaComfort=N&ck_sofaLegs=502300&ck_sofaRemovableUpholster=AVTAG&ck_sofaUpholster=SITS-TY-LEAF_114064",
        "name": "Modern Two-Seater Sofa with Plush Cushions",
        "dimension": {
            "unit": "m",
            "width": 2.2600000000000002,
            "height": 0.81,
            "length": 1.02
        },
        "new": true,
        "track": true,
        "target_position": [-2.2, 0.01, 0],
        "target_rotation": [Math.PI / 2, Math.PI, Math.PI]
    }

    const [assets, setAssets] = useState(initialAssets);
    const [queued, setQueued] = useState([sofa]);


    useEffect(() => {
        if (step === 3) {
            setQueued([]);
            setAssets(prevAssets => ({ ...prevAssets, "sofa": sofa, }));
        }



        if (step === 5) {
            setAssets(prevAssets => ({ ...prevAssets, "carpet": carpet, }));
        }

        if (step === 6) {
            setAssets(prevAssets => ({ ...prevAssets, "table": table, }));
        }

        if (step === 7) {
            const { track: track1, ...sofaWithoutTrack } = sofa;
            const { track: track2, ...carpetWithoutTrack } = carpet;
            const { track: track3, ...tableWithoutTrack } = table;
            setAssets({ "sofa": sofaWithoutTrack, "carpet": carpetWithoutTrack, "table": tableWithoutTrack, ...endAssets });
        }
    }, [step]);

    return (
        <RoomEditor room={room} assets={assets} queued={queued} setStep={setStep} step={step} />
    );
}
